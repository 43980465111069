import store from '@/store/index';
import { Message } from '@arco-design/web-react';
import axios from 'axios';

axios.defaults.timeout = 30000;
// 处理错误
function checkStatus(response: any) {
  Message.clear();
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  const errortext = response.statusText || '';
  Message.error(`请求错误 ${response.status}: ${response.url}`);

  const error = new Error(errortext);
  error.name = response.status;
  // error.response = response;
  throw error;
}
function checkException(e: any) {
  Message.error(e.message);
  throw e;
}

function checkResponse(response: any) {
  // 如果headers中的contentType不包含application/json， 返回源数据
  if (
    response.headers['content-type'] &&
    !response.headers['content-type'].includes('application/json')
  ) {
    return response.data;
  }

  if (response.data) {
    // 大于1000 的是业务错误，弹框
    switch (response.data.code) {
      case 200:
        return response.data;
      case 401:
      case 403: {
        // 清空state
        localStorage.removeItem('token');
        break;
      }
      default:
        throw new Error(`[${response.data.code}] ${response.data.message}`);
    }
    return response.data;
  }
}

export default function request(route: string, options?: any) {
  const token = localStorage.getItem('token');
  const method = options.method.toLowerCase();
  if (!('headers' in options)) {
    options.headers = {};
  }
  if (method === 'get') {
    options.params = options.data ? options.data : {};
  } else {
    if (
      'Content-Type' in options.headers &&
      options.headers['Content-Type'] === 'multipart/form-data'
    ) {
      const formData = new FormData();
      for (const item in options.data) {
        if (options.data.hasOwnProperty(item)) {
          formData.append(item, options.data[item]);
        }
      }
      options.data = formData;
    }
  }
  if (token) {
    options.headers['token'] = token;
  }

  options.url = process.env.REACT_APP_API_URL + route;
  return axios
    .request(options)
    .then(checkStatus)
    .then(checkResponse)
    .catch(checkException);
}
