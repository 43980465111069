export const PermissionUserView = 'user:view';
export const PermissionUserEdit = 'user:edit';
export const PermissionUserDelete = 'user:delete';

export const PermissionRoleView = 'role:view';
export const PermissionRoleEdit = 'role:edit';
export const PermissionRoleDelete = 'role:delete';

export const UserPermissionMechanismView = 'user:mechanism:view'; // 机构查看
export const UserPermissionMechanismEdit = 'user:mechanism:edit'; // 机构编辑
export const UserPermissionMechanismDelete = 'user:mechanism:delete'; // 机构删除
export const UserPermissionMechanismModelAuthorize =
  'user:mechanism:model:authorize'; // 模型授权
export const UserPermissionMechanismPaymentSetting =
  'user:mechanism:model:paymentSetting'; // 机构配置编辑

export const UserPermissionParameterView = 'user:parameter:view'; // 参数查看
export const UserPermissionParameterEdit = 'user:parameter:edit'; // 参数编辑
export const UserPermissionParameterDelete = 'user:parameter:delete'; // 参数删除
export const UserPermissionLLMModelView = 'user:llm:model:view'; // LLM模型查看
export const UserPermissionLLMModelEdit = 'user:llm:model:edit'; // LLM模型编辑
export const UserPermissionLLMModelDelete = 'user:llm:model:delete'; // LLM模型删除

export const UserPermissionLLMModelKeyView = 'user:llm:model:key:view'; // LLM模型KEY查看
export const UserPermissionLLMModelKeyEdit = 'user:llm:model:key:edit'; // LLM模型KEY编辑
export const UserPermissionLLMModelKeyDelete = 'user:llm:model:key:delete'; // LLM模型KEY删除
export const UserPermissionLLMModelKeyRelease = 'user:llm:model:key:release'; // LLM模型KEY释放资源

export const UserPermissionConfigView = 'user:config:view'; // 配置查看
export const UserPermissionConfigEdit = 'user:config:edit'; // 配置编辑

export const UserPermissionEvaluateConfigView = 'user:evaluate:config:view'; // 评审设置查看
export const UserPermissionEvaluateConfigEdit = 'user:evaluate:config:edit'; // 评审设置编辑
export const UserPermissionEvaluateChartsView = 'user:evaluate:charts:view'; // 评审统计查看
export const UserPermissionEvaluateView = 'user:evaluate:view'; // 评审查看

export const UserPermissionFinanceRecordView = 'user:finance:record:view'; // 财务流水查看
export const UserPermissionFinanceRecordExport = 'user:finance:record:export'; // 财务流水导出
export const UserPermissionFinanceRecharge = 'user:finance:recharge'; // 财务流水查看
export const UserPermissionFinanceRegisterRechargeConfig =
  'user:finance:register:recharge:config'; // 财务注册赠送管理

export const UserPermissionFinanceBillView = 'user:finance:bill:view'; // 发票查看
export const UserPermissionFinanceBillEdit = 'user:finance:bill:edit'; // 开票
