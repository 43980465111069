/* eslint-disable */
import request from './request';

/**
 * 登录
 *
 * @param data
 * @returns
 */
export async function auth(data: any) {
  return request('/authService/backend/login', {
    data,
    method: 'post',
  });
}

/**
 * 获取个人资料
 *
 * @param data
 * @returns
 */
export async function profile(data: any) {
  return request('/authService/backend/profile', {
    data,
    method: 'get',
  });
}

/**
 * 修改个人资料
 *
 * @param data
 * @returns
 */
export async function profileUpdate(data: any) {
  return request('/authService/backend/profile/update', {
    data,
    method: 'post',
  });
}

/**
 * 获取机构列表
 *
 * @param data
 * @returns
 */
export async function mechanismPaginate(data: any) {
  return request('/apiService/backend/mechanism/paginate', {
    data,
    method: 'get',
  });
}

/**
 * 获取机构列表
 *
 * @param data
 * @returns
 */
export async function mechanismListGet(data: any) {
  return request('/apiService/backend/mechanism/list', {
    data,
    method: 'get',
  });
}
/**
 * 编辑机构
 *
 * @param data
 * @returns
 */
export async function mechanismUpdateOrCreate(data: any) {
  return request('/apiService/backend/mechanism/updateOrCreate', {
    data,
    method: 'post',
  });
}

/**
 * 编辑机构备注
 *
 * @param data
 * @returns
 */
export async function mechanismUpdateRemark(data: any) {
  return request('/apiService/backend/mechanism/update/remark', {
    data,
    method: 'post',
  });
}

/**
 * 编辑机构权限
 *
 * @param data
 * @returns
 */
export async function mechanismUpdatePermission(data: any) {
  return request('/apiService/backend/mechanism/update/permission', {
    data,
    method: 'post',
  });
}

/**
 * 获取机构权限列表
 *
 * @param data
 * @returns
 */
export async function mechanismPermissionList(data: any) {
  return request('/apiService/backend/mechanism/permission/list', {
    data,
    method: 'get',
  });
}

/**
 * 分页获取模型列表
 *
 * @param data
 * @returns
 */
export async function llmModelPaginate(data: any) {
  return request('/apiService/backend/llmModel/paginate', {
    data,
    method: 'get',
  });
}

/**
 * 获取模型列表
 *
 * @param data
 * @returns
 */
export async function llmModelList(data: any) {
  return request('/apiService/backend/llmModel/list', {
    data,
    method: 'get',
  });
}

/**
 * 创建或编辑模型
 *
 * @param data
 * @returns
 */
export async function llmModelUpdateOrCreate(data: any) {
  return request('/apiService/backend/llmModel/updateOrCreate', {
    data,
    method: 'post',
  });
}

/**
 * 删除模型
 *
 * @param data
 * @returns
 */
export async function llmModelDelete(data: any) {
  return request('/apiService/backend/llmModel/delete', {
    data,
    method: 'post',
  });
}

/**
 * 修改模型状态
 *
 * @param data
 * @returns
 */
export async function llmModelUpdateStatus(data: any) {
  return request('/apiService/backend/llmModel/update/status', {
    data,
    method: 'post',
  });
}

/**
 * 修改模型默认注册授权状态
 *
 * @param data
 * @returns
 */
export async function llmModelUpdateAuthorizeStatus(data: any) {
  return request(
    '/apiService/backend/llmModel/update/authorizeOnRegistStatus',
    {
      data,
      method: 'post',
    }
  );
}

/**
 * 授权模型给机构
 *
 * @param data
 * @returns
 */
export async function llmModelAuthorize(data: any) {
  return request('/apiService/backend/llmModel/authorize', {
    data,
    method: 'post',
  });
}

/**
 * 分页获取参数配置列表
 *
 * @param data
 * @returns
 */
export async function parameterPaginate(data: any) {
  return request('/apiService/backend/parameter/paginate', {
    data,
    method: 'get',
  });
}

/**
 * 获取参数配置详情
 *
 * @param data
 * @returns
 */
export async function parameterDetail(data: any) {
  return request('/apiService/backend/parameter/detail', {
    data,
    method: 'get',
  });
}

/**
 * 创建参数配置
 *
 * @param data
 * @returns
 */
export async function parameterCreate(data: any) {
  return request('/apiService/backend/parameter/create', {
    data,
    method: 'post',
  });
}

/**
 * 修改参数配置名称
 *
 * @param data
 * @returns
 */
export async function parameterUpdateName(data: any) {
  return request('/apiService/backend/parameter/update/name', {
    data,
    method: 'post',
  });
}

/**
 * 修改参数配置状态
 *
 * @param data
 * @returns
 */
export async function parameterUpdateStatus(data: any) {
  return request('/apiService/backend/parameter/update/status', {
    data,
    method: 'post',
  });
}

/**
 * 修改参数配置配置
 *
 * @param data
 * @returns
 */
export async function parameterUpdateConfig(data: any) {
  return request('/apiService/backend/parameter/update/config', {
    data,
    method: 'post',
  });
}
/**
 * 修改参数配置默认配置
 *
 * @param data
 * @returns
 */
export async function parameterUpdateDefaultConfig(data: any) {
  return request('/apiService/backend/parameter/update/defaultConfig', {
    data,
    method: 'post',
  });
}

/**
 * 修改参数配置配置
 *
 * @param data
 * @returns
 */
export async function parameterDelete(data: any) {
  return request('/apiService/backend/parameter/delete', {
    data,
    method: 'post',
  });
}

/**
 * 修改系统设置
 *
 * @param data
 * @returns
 */
export async function systemSettingUpdate(data: any) {
  return request('/apiService/backend/systemSetting/update', {
    data,
    method: 'post',
  });
}

/**
 * 获取系统设置
 *
 * @param data
 * @returns
 */
export async function systemSettingGet(data: any) {
  return request('/apiService/backend/systemSetting/get', {
    data,
    method: 'post',
  });
}

/**
 * 获取OSS配置
 *
 * @param data
 * @returns
 */
export async function commonOssStsTokenGet(data: any) {
  return request('/apiService/backend/oss/stsToken', {
    data,
    method: 'get',
  });
}

/**
 * 获取角色列表
 *
 * @param data
 * @returns
 */
export async function roleList(data: any) {
  return request('/authService/backend/role/list', {
    data,
    method: 'get',
  });
}
/**
 * 获取角色列表(分页)
 *
 * @param data
 * @returns
 */
export async function rolePaginate(data: any) {
  return request('/authService/backend/role/paginate', {
    data,
    method: 'get',
  });
}

/**
 * 修改或编辑角色
 *
 * @param data
 * @returns
 */
export async function roleUpdateOrCreate(data: any) {
  return request('/authService/backend/role/updateOrCreate', {
    data,
    method: 'post',
  });
}

/**
 * 删除角色
 *
 * @param data
 * @returns
 */
export async function roleDelete(data: any) {
  return request('/authService/backend/role/delete', {
    data,
    method: 'post',
  });
}

/**
 * 获取角色权限列表
 *
 * @param data
 * @returns
 */
export async function rolePermissionList(data: any) {
  return request('/authService/backend/role/permission/list', {
    data,
    method: 'get',
  });
}

/**
 * 获取用户列表
 *
 * @param data
 * @returns
 */
export async function userPaginate(data: any) {
  return request('/authService/backend/user/paginate', {
    data,
    method: 'get',
  });
}

/**
 * 获取用户列表
 *
 * @param data
 * @returns
 */
export async function userList(data: any) {
  return request('/authService/backend/user/list', {
    data,
    method: 'get',
  });
}

/**
 * 修改或编辑用户
 *
 * @param data
 * @returns
 */
export async function userUpdateOrCreate(data: any) {
  return request('/authService/backend/user/updateOrCreate', {
    data,
    method: 'post',
  });
}

/**
 * 删除用户
 *
 * @param data
 * @returns
 */
export async function userDelete(data: any) {
  return request('/authService/backend/user/delete', {
    data,
    method: 'post',
  });
}

/**
 * 编辑用户备注
 *
 * @param data
 * @returns
 */
export async function userUpdateRemark(data: any) {
  return request('/authService/backend/user/updateRemark', {
    data,
    method: 'post',
  });
}

/**
 * 获取流水明细
 *
 * @param data
 * @returns
 */
export async function walletRecordPaginate(data: any) {
  return request('/apiService/backend/wallet/record/paginate', {
    data,
    method: 'get',
  });
}

/**
 * 充值
 *
 * @param data
 * @returns
 */
export async function walletRecharge(data: any) {
  return request('/apiService/backend/wallet/recharge', {
    data,
    method: 'post',
  });
}

/**
 * 获取流水统计
 *
 * @param data
 * @returns
 */
export async function walletCharts(data: any) {
  return request('/apiService/backend/wallet/charts', {
    data,
    method: 'get',
  });
}

/**
 * 流水导出
 *
 * @param data
 * @returns
 */
export async function walletRecordExport(data: any) {
  return request('/apiService/backend/wallet/record/export', {
    data,
    method: 'post',
    responseType: 'blob',
  });
}

/**
 * 修改机构设置
 *
 * @param data
 * @returns
 */
export async function mechanismSettingUpdate(data: any) {
  return request('/apiService/backend/mechanismSetting/update', {
    data,
    method: 'post',
  });
}

/**
 * 获取机构设置
 *
 * @param data
 * @returns
 */
export async function mechanismSettingGet(data: any) {
  return request('/apiService/backend/mechanismSetting/get', {
    data,
    method: 'post',
  });
}

/**
 * 获取模型的key列表
 *
 * @param data
 * @returns
 */
export async function llmModelKeyList(data: any) {
  return request('/apiService/backend/llmModel/key/list', {
    data,
    method: 'get',
  });
}

/**
 * 创建模型的key
 *
 * @param data
 * @returns
 */
export async function llmModelKeyCreate(data: any) {
  return request('/apiService/backend/llmModel/key/create', {
    data,
    method: 'post',
  });
}

/**
 * 修改模型的key
 *
 * @param data
 * @returns
 */
export async function llmModelKeyUpdate(data: any) {
  return request('/apiService/backend/llmModel/key/update', {
    data,
    method: 'post',
  });
}

/**
 * 删除模型的key
 *
 * @param data
 * @returns
 */
export async function llmModelKeyDelete(data: any) {
  return request('/apiService/backend/llmModel/key/delete', {
    data,
    method: 'post',
  });
}

/**
 * 检查模型key的状态
 *
 * @param data
 * @returns
 */
export async function llmModelKeyCheck(data: any) {
  return request('/apiService/backend/llmModel/key/check', {
    data,
    method: 'post',
  });
}

/**
 * 释放分配的key
 *
 * @param data
 * @returns
 */
export async function llmModelKeyAssignRelease(data: any) {
  return request('/apiService/backend/llmModel/key/assign/release', {
    data,
    method: 'post',
  });
}
/**
 * key的分配历史
 *
 * @param data
 * @returns
 */
export async function llmModelKeyAssignList(data: any) {
  return request('/apiService/backend/llmModel/key/assign/list', {
    data,
    method: 'get',
  });
}

/**
 * 获取项目评审申请列表
 *
 * @param data
 * @returns
 */
export async function projectExpertReviewPaginate(data: any) {
  return request('/apiService/backend/project/expertReview/paginate', {
    data,
    method: 'get',
  });
}

/**
 * 为项目评审分配用户
 *
 * @param data
 * @returns
 */
export async function projectExpertReviewSetUser(data: any) {
  return request('/apiService/backend/project/expertReview/setUser', {
    data,
    method: 'post',
  });
}

/**
 * 获取项目评审统计
 *
 * @param data
 * @returns
 */
export async function projectExpertReviewStatistics(data: any) {
  return request('/apiService/backend/project/expertReview/statistics', {
    data,
    method: 'get',
  });
}

/**
 * 获取我的项目评审列表
 *
 * @param data
 * @returns
 */
export async function projectExpertReviewViewPaginate(data: any) {
  return request('/apiService/backend/project/expertReview/view/paginate', {
    data,
    method: 'get',
  });
}

/**
 * 获取我的项目评审详情
 *
 * @param data
 * @returns
 */
export async function projectExpertReviewProjectDetail(data: any) {
  return request('/apiService/backend/project/expertReview/detail', {
    data,
    method: 'get',
  });
}

/**
 * 项目评审里通过结果条目来修改功能点
 *
 * @param data
 * @returns
 */
export async function projectExpertReviewUpdateResultItemByFuncItem(data: any) {
  return request(
    '/apiService/backend/project/expertReview/update/resultItemByFuncItem',
    {
      data,
      method: 'post',
    }
  );
}

/**
 * 项目评审里修改功能点
 *
 * @param data
 * @returns
 */
export async function projectExpertReviewUpdateFuncItem(data: any) {
  return request('/apiService/backend/project/expertReview/update/resultItem', {
    data,
    method: 'post',
  });
}

/**
 * 项目评审里修改功能点
 *
 * @param data
 * @returns
 */
export async function projectExpertReviewSelectParameterGet(data: any) {
  return request('/apiService/backend/project/expertReview/selectParameter', {
    data,
    method: 'get',
  });
}

/**
 * 项目评审里修改功能点
 *
 * @param data
 * @returns
 */
export async function projectExpertReviewSelectParameterUpdate(data: any) {
  return request(
    '/apiService/backend/project/expertReview/update/selectParameter',
    {
      data,
      method: 'post',
    }
  );
}

/**
 * 将项目评审状态修改为进行中
 *
 * @param data
 * @returns
 */
export async function projectExpertReviewUpdateStatusToProcessing(data: any) {
  return request(
    '/apiService/backend/project/expertReview/updateStatusToProcessing',
    {
      data,
      method: 'post',
    }
  );
}
/**
 * 完成评审
 *
 * @param data
 * @returns
 */
export async function projectExpertReviewComplete(data: any) {
  return request('/apiService/backend/project/expertReview/complete', {
    data,
    method: 'post',
  });
}
/**
 * 驳回评审
 *
 * @param data
 * @returns
 */
export async function projectExpertReviewReject(data: any) {
  return request('/apiService/backend/project/expertReview/reject', {
    data,
    method: 'post',
  });
}

/**
 * 获取我的项目评审中可设置的参数列表
 *
 * @param data
 * @returns
 */
export async function projectExpertReviewParamterPaginate(data: any) {
  return request(
    '/apiService/backend/project/expertReview/parameter/paginate',
    {
      data,
      method: 'get',
    }
  );
}

/**
 * 获取开票记录
 *
 * @param data
 * @returns
 */
export async function invoicePaginate(data: any) {
  return request('/apiService/backend/invoice/paginate', {
    data,
    method: 'get',
  });
}

/**
 * 确认开票
 *
 * @param data
 * @returns
 */
export async function invoiceDone(data: any) {
  return request('/apiService/backend/invoice/done', {
    data,
    method: 'post',
  });
}

/**
 * 驳回开票
 *
 * @param data
 * @returns
 */
export async function invoiceRefuse(data: any) {
  return request('/apiService/backend/invoice/refuse', {
    data,
    method: 'post',
  });
}

/**
 * 获取开票详情
 *
 * @param data
 * @returns
 */
export async function invoiceDetail(data: any) {
  return request('/apiService/backend/invoice/detail', {
    data,
    method: 'get',
  });
}

/**
 * 获取基准数据列表
 *
 * @param data
 * @returns
 */
export async function parameterDataSourceList(data: any) {
  return request('/apiService/backend/parameter/dataSource/list', {
    data,
    method: 'get',
  });
}

/**
 * 创建/修改基准数据
 *
 * @param data
 * @returns
 */
export async function parameterDataSourceUpdateOrCreate(data: any) {
  return request('/apiService/backend/parameter/dataSource/updateOrCreate', {
    data,
    method: 'post',
  });
}

/**
 * 删除基准数据
 *
 * @param data
 * @returns
 */
export async function parameterDataSourceDelete(data: any) {
  return request('/apiService/backend/parameter/dataSource/delete', {
    data,
    method: 'post',
  });
}

/**
 * 获取基准数据详情
 *
 * @param data
 * @returns
 */
export async function parameterDataSourceDetail(data: any) {
  return request('/apiService/backend/parameter/dataSource/detail', {
    data,
    method: 'get',
  });
}

/**
 * 获取基准数据列表
 *
 * @param data
 * @returns
 */
export async function parameterDataSourcePdrList(data: any) {
  return request('/apiService/backend/parameter/dataSource/pdr/list', {
    data,
    method: 'get',
  });
}

/**
 * 创建/修改基准数据
 *
 * @param data
 * @returns
 */
export async function parameterDataSourcePdrUpdateOrCreate(data: any) {
  return request(
    '/apiService/backend/parameter/dataSource/pdr/updateOrCreate',
    {
      data,
      method: 'post',
    }
  );
}

/**
 * 删除基准数据
 *
 * @param data
 * @returns
 */
export async function parameterDataSourcePdrDelete(data: any) {
  return request('/apiService/backend/parameter/dataSource/pdr/delete', {
    data,
    method: 'post',
  });
}

/**
 * 获取基准数据详情
 *
 * @param data
 * @returns
 */
export async function parameterDataSourcePdrDetail(data: any) {
  return request('/apiService/backend/parameter/dataSource/pdr/detail', {
    data,
    method: 'get',
  });
}

/**
 * 获取基准数据列表
 *
 * @param data
 * @returns
 */
export async function parameterDataSourceFList(data: any) {
  return request('/apiService/backend/parameter/dataSource/f/list', {
    data,
    method: 'get',
  });
}

/**
 * 创建/修改基准数据
 *
 * @param data
 * @returns
 */
export async function parameterDataSourceFUpdateOrCreate(data: any) {
  return request('/apiService/backend/parameter/dataSource/f/updateOrCreate', {
    data,
    method: 'post',
  });
}

/**
 * 删除基准数据
 *
 * @param data
 * @returns
 */
export async function parameterDataSourceFDelete(data: any) {
  return request('/apiService/backend/parameter/dataSource/f/delete', {
    data,
    method: 'post',
  });
}

/**
 * 获取基准数据详情
 *
 * @param data
 * @returns
 */
export async function parameterDataSourceFDetail(data: any) {
  return request('/apiService/backend/parameter/dataSource/f/detail', {
    data,
    method: 'get',
  });
}

/**
 * 获取参数数据项列表
 *
 * @param data
 * @returns
 */
export async function parameterItemList(data: any) {
  return request('/apiService/backend/parameter/item/list', {
    data,
    method: 'get',
  });
}

/**
 * 创建/修改参数数据项
 *
 * @param data
 * @returns
 */
export async function parameterItemUpdateOrCreate(data: any) {
  return request('/apiService/backend/parameter/item/updateOrCreate', {
    data,
    method: 'post',
  });
}

/**
 * 删除参数数据项
 *
 * @param data
 * @returns
 */
export async function parameterItemDelete(data: any) {
  return request('/apiService/backend/parameter/item/delete', {
    data,
    method: 'post',
  });
}

/**
 * 获取参数数据项详情
 *
 * @param data
 * @returns
 */
export async function parameterItemDetail(data: any) {
  return request('/apiService/backend/parameter/item/detail', {
    data,
    method: 'get',
  });
}

/**
 * 获取参数数据配置列表
 *
 * @param data
 * @returns
 */
export async function parameterItemConfigList(data: any) {
  return request('/apiService/backend/parameter/item/config/list', {
    data,
    method: 'get',
  });
}

/**
 * 创建/修改参数数据配置
 *
 * @param data
 * @returns
 */
export async function parameterItemConfigUpdateOrCreate(data: any) {
  return request('/apiService/backend/parameter/item/config/updateOrCreate', {
    data,
    method: 'post',
  });
}

/**
 * 删除参数数据配置
 *
 * @param data
 * @returns
 */
export async function parameterItemConfigDelete(data: any) {
  return request('/apiService/backend/parameter/item/config/delete', {
    data,
    method: 'post',
  });
}

/**
 * 获取参数数据配置详情
 *
 * @param data
 * @returns
 */
export async function parameterItemConfigDetail(data: any) {
  return request('/apiService/backend/parameter/item/config/detail', {
    data,
    method: 'get',
  });
}

/**
 * 获取参数数据PDR配置列表
 *
 * @param data
 * @returns
 */
export async function parameterItemPdrConfigList(data: any) {
  return request('/apiService/backend/parameter/item/pdrConfig/list', {
    data,
    method: 'get',
  });
}

/**
 * 创建/修改参数数据PDR配置
 *
 * @param data
 * @returns
 */
export async function parameterItemPdrConfigUpdate(data: any) {
  return request('/apiService/backend/parameter/item/pdrConfig/update', {
    data,
    method: 'post',
  });
}

/**
 * 获取参数数据配置详情
 *
 * @param data
 * @returns
 */
export async function parameterItemPdrConfigDetail(data: any) {
  return request('/apiService/backend/parameter/item/pdrConfig/detail', {
    data,
    method: 'get',
  });
}

/**
 * 获取参数默认值配置信息
 *
 * @param data
 * @returns
 */
export async function parameterDefaultSettingGet(data: any) {
  return request('/apiService/backend/parameter/defaultConfig', {
    data,
    method: 'get',
  });
}

/**
 * 设置参数默认值配置信息
 *
 * @param data
 * @returns
 */
export async function parameterDefaultSettingSet(data: any) {
  return request('/apiService/backend/parameter/defaultConfig/set', {
    data,
    method: 'post',
  });
}
